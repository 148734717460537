import { Variant } from '@library/_buttons/button/_types'
import { ChannelThemeInterface } from '@shared/interfaces/creators/channel-theme.interface'

export function getButtonCssVariables(
  variant: Variant,
  channelTheme?: ChannelThemeInterface,
): Record<string, unknown> {
  // Initialize css variables
  const buttonCssVariables: Record<string, unknown> = {
    '--svgFill': '',
    '--background': '',
    '--color': '',
  }

  // If channel theme set variables based of theme and override variant
  if (channelTheme) {
    buttonCssVariables['--svgFill'] =
      variant === 'dottedBorder' ? channelTheme.buttonColour : channelTheme.buttonFontColour
    buttonCssVariables['--background'] = channelTheme.buttonColour
    buttonCssVariables['--color'] = channelTheme.buttonFontColour
    return buttonCssVariables
  }

  // Depending on variant set variables
  switch (variant) {
    case 'primary':
      buttonCssVariables['--svgFill'] = 'var(--offBlackNew)'
      buttonCssVariables['--background'] = 'var(--vidzingNewEarth)'
      buttonCssVariables['--color'] = 'var(--offBlackNew)'
      return buttonCssVariables
    case 'secondary':
      buttonCssVariables['--svgFill'] = 'white'
      buttonCssVariables['--background'] = 'var(--skyEarthGradient)'
      buttonCssVariables['--color'] = 'var(--vidzingEarth)'
      return buttonCssVariables
    case 'dottedBorder':
      buttonCssVariables['--svgFill'] = 'var(--vidzingNewEarth)'
      buttonCssVariables['--background'] = 'var(--skyEarthGradient)'
      buttonCssVariables['--color'] = 'var(--skyEarthGradient)'
      return buttonCssVariables
    case 'success':
      buttonCssVariables['--svgFill'] = 'white'
      buttonCssVariables['--background'] = 'var(--vidzingAccentGreen50)'
      buttonCssVariables['--color'] = 'white'
      return buttonCssVariables
    case 'white':
      buttonCssVariables['--svgFill'] = 'white'
      buttonCssVariables['--background'] = 'transparent'
      buttonCssVariables['--color'] = 'var(--offWhite)'
      return buttonCssVariables
    case 'black':
      buttonCssVariables['--svgFill'] = 'black'
      buttonCssVariables['--background'] = 'transparent'
      buttonCssVariables['--color'] = 'var(--offBlackNew)'
      return buttonCssVariables
  }
}
